<template>
  <div class="container">
    <div class="main">
      <div class="table-box">
        <h4>社会实践</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>设计制作</th>
            <th>劳动体验</th>
            <th>社会服务</th>
            <th>素质拓展</th>
            <th>考察探究</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{ socialForm.designCount }}</td>
            <td>{{ socialForm.workCount }}</td>
            <td>{{ socialForm.serviceCount }}</td>
            <td>{{ socialForm.qualityCount }}</td>
            <td>{{ socialForm.inspectCount }}</td>
            <td>{{ socialForm.sumCount }}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{ socialForm.designScore }}</td>
            <td>{{ socialForm.workScore }}</td>
            <td>{{ socialForm.serviceScore }}</td>
            <td>{{ socialForm.qualityScore }}</td>
            <td>{{ socialForm.inspectScore }}</td>
            <td>{{ socialForm.sumScore }}</td>
          </tr>
        </table>
      </div>

      <div class="more">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <h4>相关记录</h4>
          </el-col>
        </el-row>
        <el-table
          :data="socialList"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="practiceTitle" label="社会实践主题">
          </el-table-column>
          <el-table-column prop="practiceLabel" label="指标标签">
          </el-table-column>
          <el-table-column prop="practiceType" label="实践类别">
          </el-table-column>
          <el-table-column prop="practiceAddress" label="实践地点">
          </el-table-column>
          <el-table-column prop="practiceTime" label="实践时间">
          </el-table-column>
          <el-table-column prop="submitStatus" label="心得感悟">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailSocial(scope.row)"
                type="text"
                size="small"
                >查看详情</el-button
              >
              <el-button
                v-if="scope.row.submitStatus == '未写' && roleKey == 'student'"
                @click="handleUnderstand(scope.row)"
                type="text"
                size="small"
                >心得感悟</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 查看详情 -->
    <el-dialog
      title="团体社会实践详情"
      :visible.sync="open"
      width="600px"
      center
    >
      <el-form
        ref="detailForm"
        :model="detailForm"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding: 5px 0 10px 0;
        "
      >
        <el-form-item label="社会实践主题：">
          <span class="scontent">{{ detailForm.practiceTitle }}</span>
        </el-form-item>
        <el-form-item label="指标标签：">
          <span class="scontent">{{ detailForm.practiceLabel }}</span>
        </el-form-item>
        <el-form-item label="实践时间：">
          <span class="scontent">{{ detailForm.practiceTime }}</span>
        </el-form-item>
        <el-form-item label="实践地点：">
          <span class="scontent">{{ detailForm.practiceAddress }}</span>
        </el-form-item>
        <el-form-item label="参与学生：">
          <el-button size="mini" type="primary" @click="innerVisible = true"
            >查看</el-button
          >
          <el-dialog
            width="500px"
            title="参与学生"
            :visible.sync="innerVisible"
            append-to-body
            center
          >
            <div class="dialog-name">
              <h3>学生名单（共{{ detailForm.userNames.length }}人）</h3>
              <ul style="display: flex; flex-wrap: wrap">
                <li
                  v-for="(item, index) in detailForm.userNames"
                  :key="index"
                  style="
                    font-size: 14px;
                    color: #303133;
                    line-height: 48px;
                    margin-right: 16px;
                  "
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="innerVisible = false">关 闭</el-button>
            </div>
          </el-dialog>
        </el-form-item>
        <el-form-item label="实践图片：">
          <el-image
            v-for="(item, index) in detailForm.practiceImg"
            :key="index"
            fit="contain"
            :src="imgSrc + item"
          ></el-image>
        </el-form-item>
		<el-divider content-position="left">实践内容描述</el-divider>
		<div class="scontent" v-html="detailForm.practiceContext"></div>
        <template v-if="detailForm.submitStatus != '未写'">
			<el-divider content-position="left">心得感悟</el-divider>
			<div class="scontent" v-html="detailForm.perContext"></div>
          <el-form-item>
            <el-image
              v-for="(item, index) in detailForm.perImg"
              :key="index"
              fit="contain"
              :src="imgSrc + item"
            ></el-image>
          </el-form-item>
          <el-form-item
            label="基地评分："
            v-if="detailForm.practiceType == '实践基地'"
          >
            <el-rate
              v-model="detailForm.baseScore"
              style="margin-top: 10px"
			  :disabled="true"
            ></el-rate>
          </el-form-item>
          <el-form-item label="心得感悟状态：">
            <span class="scontent">{{ detailForm.verifyStatus }}</span>
          </el-form-item>
          <el-form-item
            label="驳回原因："
            v-if="detailForm.verifyStatus == '已驳回'"
          >
            <span class="scontent" style="color: red">{{
              detailForm.rejectCause
            }}</span>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
        <el-button
          @click="handleGoPublish"
          type="primary"
          v-if="detailForm.submitStatus == '未写' && roleKey == 'student'"
          >写心得感悟</el-button
        >
        <el-button
          @click="handleRePublish"
          type="primary"
          v-if="detailForm.verifyStatus == '已驳回' && roleKey == 'student'"
          >重新编辑</el-button
        >
      </div>
    </el-dialog>

    <!-- 发布心得感悟 -->
    <el-dialog
      title="心得感悟"
      :visible.sync="openPublish"
      width="600px"
      center
	  @opened="initEditor"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding: 5px 0 10px 0;
        "
      >
        <el-form-item label="社会实践主题：">
          <span class="scontent">{{ form.practiceTitle }}</span>
        </el-form-item>
        <el-form-item label="心得感悟：" prop="perContext">
          <div id="editor" class="edtr" ref="editor"></div>
        </el-form-item>
        <el-form-item  label="佐证材料：">
          <el-upload
            :action="upload.action"
            :accept="upload.accept"
            :limit="upload.limit"
            :multiple="upload.multiple"
            :file-list="upload.list"
            :on-change="uploadChange"
            :on-remove="uploadRemove"
            :auto-upload="upload.auto"
          >
            <el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="基地评分："
          prop="baseScore"
          v-if="form.practiceType == '实践基地'"
        >
          <el-rate v-model="form.baseScore" style="margin-top: 10px"></el-rate>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openPublish = false">取 消</el-button>
        <el-button @click="handleSubmitPublish" type="primary">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getResultDetail, getResultById } from "@/api/teacher/quality/social";
import { getAllDetail, savePublish } from "@/api/student/myquality/shehui";
import E from "wangeditor"
import { getToken } from "@/utils/auth"
export default {
  data() {
    return {	
      imgSrc: this.imageSrc(),
      userId: "",
      socialForm: {},
      socialList: [],
      detailForm: {
        userNames: [],
      },
      open: false,
      innerVisible: false,
      openPublish: false,
      rowForm: {},
      form: {},
      rules: {
        baseScore: [
          { required: true, message: "请对基地评分", trigger: "blur" },
        ],
      },
      roleKey: "",
	  editor: {},
	  upload: {
		action: '',
		accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
		limit: 3,
		multiple: false,
		list: [],
		auto: false
	  },
    };
  },
  created() {
    if (this.$store.getters.roles.length > 0) {
      this.roleKey = this.$store.getters.roles[0].roleKey;
    }
    this.userId = this.$store.getters.userId;
    this.getList();
  },
  methods: {
    getList() {
      getResultDetail({ userId: this.userId }).then((res) => {
        // console.log(res);
        this.socialForm = res.data;
      });
      getResultById({ userId: this.userId }).then((res) => {
        this.socialList = res.data;
      });
    },
    handleDetailSocial(row) {
      this.rowForm = row;
      getAllDetail({
        recordId: row.recordId,
        classId: row.classId,
        userId: this.userId,
      }).then((res) => {
        this.detailForm = res.data;
        this.open = true;
        if (this.detailForm.practiceImg) {
          this.detailForm.practiceImg = this.detailForm.practiceImg.split(",");
        }
        if (this.detailForm.perImg) {
          this.detailForm.perImg = this.detailForm.perImg.split(",");
        }
        this.detailForm.baseScore = this.detailForm.baseScore / 2;
        this.detailForm.submitStatus = row.submitStatus;
        this.detailForm.practiceType = row.practiceType;
      });
    },
    handleGoPublish() {
      this.open = false;
      this.handleUnderstand(this.rowForm);
    },
    handleRePublish() {
      this.open = false;
      this.handleUnderstand(this.rowForm);
      this.form.baseScore = this.detailForm.baseScore;
      this.form.perContext = this.detailForm.perContext;
    },
    handleUnderstand(row) {
      this.reset();
      this.openPublish = true;
      this.form.recordId = row.recordId;
      this.form.classId = row.classId;
      this.form.userId = row.userId;
      this.form.practiceTitle = row.practiceTitle;
      this.form.practiceType = row.practiceType
    },
    handleSubmitPublish() {
      /* if(this.form.file) {
        this.form.file = this.compressImg(this.form.file)
      }; */
	  // 获取写实记录内容的txt
	  let contentTxt = this.editor.txt.text()
	  if (contentTxt.length < 100) {
		  this.$message.warning('心得感悟内容至少输入100个字')
		  return
	  }
      setTimeout(() => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            if (
              this.form.baseScore === 0 &&
              this.form.practiceType == "实践基地"
            ) {
              this.$alert("请对基地进行评分", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
            } else {
              var formData = new FormData();
              if (this.upload.list.length > 0) {
                this.upload.list.map((item) => {
                  formData.append("file", item);
                });
              }
              formData.append("recordId", this.form.recordId);
              formData.append("classId", this.form.classId);
              formData.append("userId", this.form.userId);
              formData.append("perContext", this.form.perContext);
              formData.append("baseScore", this.form.baseScore * 2);
              savePublish(formData).then((res) => {
                this.$message.success("心得感悟提交成功");
                this.openPublish = false;
                this.getList();
              });
            }
          } else {
            return false;
          }
        });
      }, 1000);
    },
    reset() {
      this.resetForm("form");
      this.form = {
        practiceTitle: undefined,
        perContext: undefined,
        fileList: [],
        file: undefined,
        baseScore: 0,
      };
	  this.upload.list = []
    },
    // 材料上传
    uploadChange(file, fileList) {
    	let rawTp = file.raw.type
    	// pdf, excel表格, word文档, 图, 纯文本
    	let allowTp = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif', 'image/png', 'image/jpeg', 'text/plain']
    	if (allowTp.indexOf(rawTp) == -1) {
    		this.$message.error("材料仅支持pdf、excel表格、word、图片与纯文本格式！")
    		fileList.pop()
    	} else {
    		this.upload.list.push(file.raw)
    		this.$message.success("上传成功")
    	}
    },
    // 材料删除
    uploadRemove(file, fileList) {
    	this.upload.list = fileList
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
	// 初始化编辑器
	initEditor() {
		var that = this;
		// 销毁已有editor实例
		if (!that.isEmpty(that.editor.id)) {
			that.editor.destroy()
			that.editor = null
		}
		that.editor = new E(that.$refs.editor)
		// 编辑器的事件，每次改变会获取其html内容
		that.editor.config.onchange = (html) => {
		  that.form.perContext = html
		}
		that.editor.config.height = 300;
		that.editor.config.showLinkImg = false;
		that.editor.config.debug = true;
		that.editor.config.uploadImgServer =
		  process.env.VUE_APP_API + "/shida-user-biz/upload/uploadImage";
		that.editor.config.uploadFileName = "file";
		that.editor.config.placeholder = "请输入写实记录内容"
		that.editor.config.uploadImgHeaders = {
		  Authorization: getToken(),
		  'role-distinction-client': 2
		};
		that.editor.config.uploadImgHooks = {
		  // 图片上传并返回结果，但图片插入错误时触发
		  fail: function (xhr, editor, result) {
		    console.error(result);
		  },
		  success: function (xhr, editor, result) {
		    // 图片上传并返回结果，图片插入成功之后触发
		  },
		  // 图片上传并返回了结果，想要自己把图片插入到编辑器中
		  // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
		  customInsert: function (insertImgFn, result) {
		    // result 即服务端返回的接口
		    // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
		    insertImgFn(that.imgSrc + result.data);
		  },
		};
		that.editor.config.menus = [
		  // 菜单配置
		  "bold", // 粗体
		  "fontSize", // 字号
		  "italic", // 斜体
		  "underline", // 下划线
		  "strikeThrough", // 删除线
		  "foreColor", // 文字颜色
		  "backColor", // 背景颜色
		  "link", // 链接
		  "undo", // 撤销
		  "redo", // 重做
		];
		that.editor.create(); // 创建富文本实例
		if (that.detailForm.verifyStatus == '已驳回') {
			that.editor.txt.html(that.detailForm.perContext)
		}
	}
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 14%;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.user-nav .el-select >>> .el-input {
  margin: 10px 12px;
}
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-form >>> .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
/* .el-image {
width: 300px;
} */
</style>